import React, { useState, useEffect } from "react"
import axios from "axios"
import { auth } from "../../auth/Auth"

const Signalement = () => {
  const [bugs, setBugs] = useState([])

  // Fonction pour récupérer la liste des bugs depuis le backend
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/bugs`, { headers: auth() }).then((res) => {
      setBugs(res.data)
    })
  }, [])

  // Créez une fonction pour mettre à jour le statut d'un signalement de bug
  const updateStatus = (bugId, newStatus) => {
    axios
      .put(
        `${process.env.REACT_APP_API}/updatebug/${bugId}`,
        { headers: auth() },
        {
          status: newStatus,
        }
      )
      .then((res) => {
        console.log("Statut du signalement et du bug mis à jour avec succès :", res.data)

        // Mettez à jour l'état local des signalements de bugs avec le nouveau statut
        setBugs((prevBugs) => prevBugs.map((bug) => (bug._id === bugId ? { ...bug, status: newStatus } : bug)))
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour du statut du signalement de bug :", error)
      })
  }

  return (
    <section className="container">
      <h1>Gestion des Signalements et Bugs</h1>
      {bugs.map((bug) => (
        <article key={bug._id} className="devis-article">
          <p className="bold">Nom : {bug.name}</p>
          <p className="bold">Email : {bug.email}</p>
          <p className="bold">Explication : {bug.explication}</p>
          <p>
            Statut:
            <span
              style={{
                backgroundColor: bug.status === "Résolue" ? "green" : bug.status === "En attente" ? "orange" : "red",
                padding: "2px 5px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              {bug.status}
            </span>
          </p>
          <div className="devis-actions">
            <button onClick={() => updateStatus(bug._id, "Résolue")}>Résolu</button>
            <button onClick={() => updateStatus(bug._id, "Non résolue")}>Non résolu</button>
          </div>
        </article>
      ))}
    </section>
  )
}

export default Signalement
