import { NavLink } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <footer className="footer">
        <h4>Retrouvez-moi aussi sur les réseaux </h4>
        <div className="lien-footer">
          <a href="https://www.instagram.com/jay_pastry/" target="_blank" rel="noopener noreferrer">
            <img className="logo-lien" src={"instagram.png"} alt="lien insta" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100010299825718" target="_blank" rel="noopener noreferrer">
            <img className="logo-lien" src={"facebook.png"} alt="lien facebook" />
          </a>
        </div>
        <div className="div-lien-footer">
          <NavLink className="NavLink-footer" to="/ma-philosophie">
            Charte de qualité
          </NavLink>
          <NavLink className="NavLink-footer" to="/signaler-bug">
            Signaler un problème
          </NavLink>
        </div>
      </footer>
    </>
  )
}

export default Footer
