import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { auth } from "../../auth/Auth"

const GestionGateaux = () => {
  const [cakes, setCakes] = useState([])
  const [message, setMessage] = useState("")

  // Fonction pour récupérer la liste des gâteaux depuis le backend
  const fetchCakes = () => {
    axios.get(`${process.env.REACT_APP_API}/cakes`, { headers: auth() }).then((res) => {
      setCakes(res.data)
      setMessage(res.data.message)
    })
  }

  useEffect(() => {
    fetchCakes()
  }, [])

  // Fonction pour supprimer un gâteau
  const handleDeleteCake = (cakeId) => {
    // Demander une confirmation avant la suppression
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce gâteau ?")

    if (confirmation) {
      axios
        .delete(`${process.env.REACT_APP_API}/deletecakes/${cakeId}`, { headers: auth() })
        .then((res) => {
          console.log("Gâteau supprimé :", res.data)
          // Rafraîchir la liste des gâteaux après la suppression
          fetchCakes()
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du gâteau :", error)
        })
    }
  }

  return (
    <section className="container">
      <h1>Gestion des Gâteaux</h1>
      <Link to="/ajouter-gateau">
        <button>Ajouter un nouveau gâteau</button>
      </Link>

      <table className="table-border">
        <thead>
          <tr>
            <th>Image</th>
            <th>Nom du Gâteau</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {cakes.map((cake) => (
            <tr key={cake._id}>
              <td>
                <img className="img-admin" src={`${process.env.REACT_APP_API}/img/${cake.img[0]}`} alt={cake.name} />
              </td>
              <td>{cake.name}</td>
              <td>
                <Link to={`/edit-cakes/${cake._id}`}>
                  <button>Modifier</button>
                </Link>
                <button onClick={() => handleDeleteCake(cake._id)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {message}
    </section>
  )
}

export default GestionGateaux
