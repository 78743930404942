import React, { useState } from "react"
import axios from "axios"
import { auth } from "../../auth/Auth"

const AddRecipe = () => {
  const [formData, setFormData] = useState({
    titre: "",
    description: "",
    category: "",
    timeprepare: "",
    heat: "",
    timeheat: "",
    numberofpeople: "",
    ingredients: [],
    preparation: [],
    img: "",
  })

  const [message, setMessage] = useState("")

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "img") {
      setFormData({ ...formData, img: e.target.files[0] })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleIngredientChange = (e, index) => {
    const { name, value } = e.target
    const newIngredients = [...formData.ingredients]
    newIngredients[index][name] = value
    setFormData({ ...formData, ingredients: newIngredients })
  }

  const handleStepChange = (e, index) => {
    const { name, value } = e.target
    const newPreparation = [...formData.preparation]
    newPreparation[index][name] = value
    setFormData({ ...formData, preparation: newPreparation })
  }

  const handleAddIngredient = () => {
    setFormData({
      ...formData,
      ingredients: [...formData.ingredients, { name: "", quantity: "" }],
    })
  }

  const handleAddStep = () => {
    setFormData({
      ...formData,
      preparation: [...formData.preparation, { stepNumber: formData.preparation.length + 1, description: "" }],
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const formDataToSend = {
        ...formData,
        ingredients: JSON.stringify(formData.ingredients),
        preparation: JSON.stringify(formData.preparation),
      }

      const formInput = new FormData()

      Object.keys(formDataToSend).forEach((key) => {
        formInput.append(key, formDataToSend[key])
      })

      console.log("Data to send:", formDataToSend)

      const response = await axios.post(`${process.env.REACT_APP_API}/addrecipe`, formInput, {
        headers: { ...auth(), "Content-Type": "multipart/form-data" },
      })

      setMessage(response.data.message)
      console.log("Server response:", response.data)

      if (response.data.success) {
        setFormData({
          titre: "",
          description: "",
          category: "",
          timeprepare: "",
          heat: "",
          timeheat: "",
          numberofpeople: "",
          ingredients: [],
          preparation: [],
          img: "",
        })
      }
    } catch (error) {
      console.error("Error while adding recipe:", error)
    }
  }

  return (
    <section className="container">
      <h1>Ajouter une nouvelle recette</h1>
      <form encType="multipart/form-data" onSubmit={handleSubmit} className="form-recette">
        <div>
          <label htmlFor="titre">Titre de la Recette :</label>
          <input type="text" name="titre" value={formData.titre} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="description">Description :</label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="category">Catégorie :</label>
          <input type="text" name="category" value={formData.category} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="timeprepare">Temps de préparation :</label>
          <input type="text" name="timeprepare" value={formData.timeprepare} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="heat">Température :</label>
          <input type="text" name="heat" value={formData.heat} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="timeheat">Temps de cuisson :</label>
          <input type="text" name="timeheat" value={formData.timeheat} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="numberofpeople">Nombre de personnes :</label>
          <input type="text" name="numberofpeople" value={formData.numberofpeople} onChange={handleChange} />
        </div>
        <div>
          <label>Liste des ingrédients :</label>
          {formData.ingredients.map((ingredient, index) => (
            <div key={index}>
              <input
                type="text"
                name="name"
                value={ingredient.name}
                onChange={(e) => handleIngredientChange(e, index)}
                placeholder="Nom de l'ingrédient"
              />
              <input
                type="text"
                name="quantity"
                value={ingredient.quantity}
                onChange={(e) => handleIngredientChange(e, index)}
                placeholder="Quantité"
              />
            </div>
          ))}
          <button type="button" onClick={handleAddIngredient}>
            Ajouter un ingrédient
          </button>
        </div>
        <div>
          <label>Étapes de préparation :</label>
          {formData.preparation.map((step, index) => (
            <div key={index}>
              <input type="text" name="stepNumber" value={step.stepNumber} readOnly />
              <textarea
                name="description"
                value={step.description}
                onChange={(e) => handleStepChange(e, index)}
                placeholder={`Étape ${step.stepNumber}`}
              />
            </div>
          ))}
          <button type="button" onClick={handleAddStep}>
            Ajouter une étape
          </button>
        </div>
        <div>
          <label htmlFor="img">Image :</label>
          <input type="file" name="img" onChange={handleChange} />
        </div>
        <button type="submit">Ajouter la recette</button>
      </form>
      {message && <div>{message}</div>}
    </section>
  )
}

export default AddRecipe
