import React, { useEffect, useState } from "react"
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useLocation } from "react-router-dom"

const Formulaire = () => {
  const [cakes, setCakes] = useState([])
  const [conditioning, setConditioning] = useState([])
  const [format, setFormat] = useState()
  const [price, setPrice] = useState()
  const [name, setName] = useState()
  const [chiffre, setChiffre] = useState()
  const [day, setDay] = useState("")
  const [hour, setHour] = useState()
  const [additionalInfo, setAdditionalInfo] = useState()
  const [clickAndCollect, setClickAndCollect] = useState()
  const [adresse, setAdresse] = useState()
  const [nameUser, setNameUser] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [telephone, setTelephone] = useState()
  const [baseValue, setBaseValue] = useState()
  const [cremeValue, setCremeValue] = useState()
  const [parfumValue, setParfumValue] = useState()
  const [decoValue, setDecoValue] = useState()
  const [message, setMessage] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [count, setCount] = useState(0)

  const [addOns, setAddOns] = useState(undefined)

  const rawQueryParams = useLocation()
  const search = new URLSearchParams(rawQueryParams.search)
  const cakeId = search.get("cakeId")
  const size = search.get("size")
  const defaultValues = !!size && !!cakeId

  const filterDate = (date) => {
    const today = new Date()
    const twoDaysFromToday = new Date(today)
    twoDaysFromToday.setDate(today.getDate() + 2)
    return date >= twoDaysFromToday
  }

  const handleDateChange = (date) => {
    // Formatage de la date en YYYY-MM-JJ
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}`

    setStartDate(date)
    setDay(formattedDate)
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/cakes`).then((res) => {
      console.log("res", res)
      setCakes(res.data)
      const allCakes = res.data
      setMessage(allCakes.message)
      const getCakeIndex = (cakeId, cakes) => cakes.findIndex((cake) => cake._id === cakeId)
      const getConditionIndex = (size, conditioning) => conditioning.findIndex((c) => c.libelle.includes(size))
      console.log("cakeid", cakeId)

      if (defaultValues) {
        const defaultCake = allCakes[getCakeIndex(cakeId, allCakes)]
        setConditioning(defaultCake.conditioning)
        setName(defaultCake.name)
        setPrice(defaultCake.conditioning.find((c) => c.libelle.includes(size))?.price)
        setFormat(defaultCake.conditioning[getConditionIndex(size, defaultCake.conditioning)].libelle)
        if (cakeId === "652e85fd8803234b3b7d46d7") {
          setAddOns(defaultCake.numbercake)
          console.log("defaultCake.numbercake", defaultCake.numbercake)
        }
      } else {
        setConditioning(res.data[0].conditioning)
      }
    }) // eslint-disable-next-line
  }, [cakeId, defaultValues, size])

  const handleChange = (e) => {
    const selectedCakeName = e.target.value
    console.log("selectedCakeName", selectedCakeName)
    setName(selectedCakeName)

    const selectedCake = cakes.find((cake) => cake.name === selectedCakeName)
    if (selectedCake) {
      // Mettre à jour la taille du gâteau avec la première option
      setFormat(selectedCake.conditioning?.libelle)
      // Mettre à jour les options de conditionnement
      setConditioning(selectedCake.conditioning)
      // Mettre à jour le prix avec le prix de la première option
      setPrice(selectedCake.conditioning?.price)

      // Mettre à jour les addons si c'est un Number Cake
      if (selectedCakeName === "Number Cake") {
        setAddOns(selectedCake.numbercake)
      } else {
        setAddOns(undefined)
        console.log("addons", addOns)
      }
    }
  }

  const handleSizeChange = (e) => {
    const selectedOption = conditioning.find((OneConditioning) => OneConditioning.libelle === e.target.value)
    if (selectedOption) {
      setPrice(selectedOption.price)
      setFormat(selectedOption.libelle)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const order = {
      cake: name,
      size: format,
      price: price,
      chiffre: chiffre,
      base: baseValue,
      creme: cremeValue,
      parfum: parfumValue,
      decorations: decoValue,
      date: day,
      time: hour,
      additionalInfo: additionalInfo,
      retrait: clickAndCollect,
      livraisonAddress: adresse,
      firstName: nameUser,
      lastName: lastName,
      email: email,
      phone: telephone,
    }
    console.log("order", order)
    axios.post(`${process.env.REACT_APP_API}/addorder`, order).then((res) => {
      setMessage(res.data.message)
      setCount(count + 1)
    })

    console.log("Formulaire soumis avec succès")
  }

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>

      <form className="formulaire-devis" onSubmit={handleSubmit}>
        <section className="section-devis">
          <h2>Information Commande</h2>
          <div className="formflex">
            <div className="formfieldflex">
              <label htmlFor="cake">Quel gâteau souhaitez-vous?</label>
              <select onChange={handleChange} name="cake" required>
                <option>---</option>
                {cakes.map((cake, index) => (
                  <option key={index} value={cake.name} selected={cakeId ? cake._id === cakeId : undefined} required>
                    {cake.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="formfieldflex">
              <label htmlFor="size">Pour combien de personnes?</label>
              <select onChange={(e) => handleSizeChange(e)} value={format} name="size" id="size" required>
                <option>---</option>
                {conditioning.map((condition, index) => (
                  <option
                    key={condition.libelle + index}
                    value={condition.libelle}
                    selected={format ? condition.libelle.includes(format) : undefined}
                    required
                  >
                    {condition.libelle}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p className="price">{price} €</p>
          <section className="section-devis" id="numberCakeOptions">
            <div className="formflex">
              {addOns && (
                <div className="formfieldflex">
                  <label htmlFor="chiffre">Quel chiffre / lettre souhaitez-vous?</label>
                  <input
                    type="text"
                    onChange={(e) => setChiffre(e.target.value)}
                    name="chiffre"
                    id="chiffre"
                    placeholder="Chiffre ou Lettre "
                    alt="Choisissez un chiffre ou une lettre"
                    required
                  />
                </div>
              )}
              {addOns && (
                <div className="formfieldflex">
                  <label htmlFor="base">Quelle base souhaitez-vous?</label>
                  <select onChange={(e) => setBaseValue(e.target.value)} name="base" id="base">
                    <option>---</option>
                    {addOns.map((addOn, index) => (
                      <>
                        {addOn.base && (
                          <option key={index} value={addOn.base}>
                            {addOn.base}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
              )}
              {addOns && (
                <div className="formfieldflex">
                  <label htmlFor="creme">Quelle crème souhaitez-vous?</label>
                  <select onChange={(e) => setCremeValue(e.target.value)} name="creme" id="creme">
                    <option>---</option>
                    {addOns.map((addOn, index) => (
                      <>
                        {addOn.creme && (
                          <option key={index} value={addOn.creme}>
                            {addOn.creme}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="formflex">
              {addOns && (
                <div className="formfieldflex">
                  <label htmlFor="parfum">Quel parfum pour votre crème?</label>
                  <select onChange={(e) => setParfumValue(e.target.value)} name="parfum" id="parfum">
                    <option value="">---</option>
                    {addOns.map((addOn, index) => (
                      <option key={index} value={addOn.parfum}>
                        {addOn.parfum}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {addOns && (
                <div className="formfieldflex">
                  <label htmlFor="decorations">Quelles décorations souhaitez-vous?</label>
                  <select onChange={(e) => setDecoValue(e.target.value)} name="decorations" id="decorations">
                    <option value="">---</option>
                    {addOns.map((addOn, index) => (
                      <>
                        {addOn.deco && (
                          <option key={index} value={addOn.deco}>
                            {addOn.deco}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </section>
          <div className="formflex">
            <div className="formfieldflex">
              <label htmlFor="start">Pour quel jour le voulez-vous?</label>
              <DatePicker
                selected={startDate}
                className="datepicker"
                onChange={(date) => handleDateChange(date)}
                minDate={new Date()} // Empêche les dates antérieures
                filterDate={filterDate} // Empêche les dates dans les deux prochains jours
              />
            </div>
            <div className="formfieldflex">
              <label htmlFor="appt">À quelle heure le souhaitez-vous?</label>
              <input
                type="time"
                className="time"
                onChange={(e) => setHour(e.target.value)}
                id="appt"
                name="appt"
                min="09:00"
                max="20:00"
                required
              />
            </div>
          </div>
          <label htmlFor="appt">Avez-vous une question ?</label>
          <textarea
            name="additionalInfo"
            onChange={(e) => setAdditionalInfo(e.target.value)}
            id="additionalInfo"
            cols="30"
            rows="5"
            alt="allergie, question ?"
            placeholder="Allergies, personnalisation, question, ..."
          ></textarea>
        </section>

        <section className="section-devis">
          <h2>Mode de Retrait</h2>
          <div className="section-flex">
            <input
              type="radio"
              className="retrait"
              onChange={(e) => setClickAndCollect(e.target.value)}
              name="retrait"
              id="clickCollect"
              value="clickCollect"
            />
            <label htmlFor="clickCollect">
              <div>
                <p className="bold">Retrait Click & Collect</p>
                <p>Place Montgolfier, 94410 Saint-Maurice</p>
              </div>
            </label>
          </div>

          <div className="section-flex">
            <input
              type="radio"
              className="livraison"
              onChange={(e) => setClickAndCollect(e.target.value)}
              name="retrait"
              id="livraison"
              value="livraison"
            />
            <label htmlFor="livraison">
              <p className="bold">Livraison (+15€)</p>
              <p id="livraisonInstruction">
                Si vous cochez la case "Livraison", merci de bien vouloir nous communiquer votre adresse.
              </p>
            </label>
          </div>

          <textarea
            name="livraisonAddress"
            onChange={(e) => setAdresse(e.target.value)}
            id="livraisonAddress"
            cols="30"
            rows="5"
            alt="votre adresse de livraison"
            placeholder="Adresse de livraison"
          ></textarea>
        </section>

        <section className="section-devis">
          <h2>Informations Personnelles</h2>
          <div className="formflex">
            <div className="formfieldflex">
              <label htmlFor="firstName">Prénom :</label>
              <input
                type="text"
                onChange={(e) => setNameUser(e.target.value)}
                name="firstName"
                id="firstName"
                placeholder="Prénom"
                alt="Prénom"
                required
              />
            </div>
            <div className="formfieldflex">
              <label htmlFor="lastname">Nom :</label>
              <input
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                name="lastname"
                id="lastname"
                placeholder="Nom"
                alt="Nom"
                required
              />
            </div>
          </div>
          <div className="formflex">
            <div className="formfieldflex">
              <label htmlFor="email">Mail :</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                name="mail"
                id="mail"
                placeholder="Adresse Mail"
                alt="Adresse Mail"
                required
              />
            </div>
            <div className="formfieldflex">
              <label htmlFor="phone">Numéro de téléphone :</label>
              <input
                type="tel"
                onChange={(e) => setTelephone(e.target.value)}
                id="phone"
                name="phone"
                pattern="[0-9]{10}"
                placeholder="Téléphone"
                alt="Numéro de Téléphone"
                required
              />
            </div>
          </div>
          <section className="section-devis">
            <h2>Et après ? </h2>
            <p>
              Vous serez contacté sous 24 heures si le devis est accepté ; un acompte de 50 % vous sera demandé afin de
              sécuriser la commande.
            </p>
            <p>Paiement possible via Paypal, Revolut, Lydia.</p>
          </section>
          {message}
        </section>
        <button className="btn btn-submit" type="submit" disabled={count === 1 ? true : false}>
          Envoyer
        </button>
      </form>
    </>
  )
}

export default Formulaire
