import React, { useState, useEffect } from "react"
import axios from "axios"
import { auth } from "../../auth/Auth"

const GestionDevis = () => {
  const [orders, setOrders] = useState([])

  // Utilisez useEffect pour charger les devis depuis le backend
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/orders`, { headers: auth() }).then((res) => {
      setOrders(res.data)
    })
  }, [])

  // Créez une fonction pour mettre à jour le statut d'un devis
  const updateStatus = (orderId, newStatus) => {
    // Faites une requête au backend pour mettre à jour le statut du devis
    axios
      .put(
        `${process.env.REACT_APP_API}/updateoneorder/${orderId}`,

        {
          status: newStatus,
        },
        { headers: auth() }
      )
      .then((res) => {
        // Le statut du devis a été mis à jour, vous pouvez gérer ici la réponse du backend
        console.log("Statut du devis mis à jour avec succès :", res.data)

        // Mettez à jour l'état local des devis avec le nouveau statut
        setOrders((prevOrders) =>
          prevOrders.map((order) => (order._id === orderId ? { ...order, status: newStatus } : order))
        )
      })
      .catch((error) => {
        // Une erreur s'est produite lors de la mise à jour du statut du devis, gérez l'erreur ici
        console.error("Erreur lors de la mise à jour du statut du devis :", error)
      })
  }

  return (
    <section className="container">
      <h1>Gestion des Devis</h1>
      {orders.map((order) => (
        <article key={order._id} className="devis-article">
          <div className="devis-details">
            <h2>{order.cake}</h2>
            <p>
              <strong>Client:</strong> {order.firstName} {order.lastName}
            </p>
            <p>
              <strong>Statut:</strong>
              <span
                style={{
                  backgroundColor: order.status === "Accepté" ? "green" : order.status === "Refusé" ? "red" : "orange",
                  padding: "2px 5px",
                  borderRadius: "5px",
                  display: "inline-block",
                }}
              >
                {order.status}
              </span>
            </p>

            <p>
              <strong>Taille:</strong> {order.size}
            </p>
            <p>
              <strong>Chiffre:</strong> {order.chiffre}
            </p>
            <p>
              <strong>Prix: </strong> {order.price}
            </p>
            <p>
              <strong>Base:</strong> {order.base}
            </p>
            <p>
              <strong>Crème:</strong> {order.creme}
            </p>
            <p>
              <strong>Parfum:</strong> {order.parfum}
            </p>
            <p>
              <strong>Décorations:</strong> {order.decorations}
            </p>
            <p>
              <strong>Date:</strong> {new Date(order.date).toLocaleDateString("fr")}
            </p>
            <p>
              <strong>Heure:</strong> {order.time}
            </p>
            <p>
              <strong>Info Additionnelle:</strong> {order.additionalInfo}
            </p>
            <p>
              <strong>Retrait:</strong> {order.retrait}
            </p>
            <p>
              <strong>Adresse de Livraison:</strong> {order.livraisonAddress}
            </p>
            <p>
              <strong>Prénom:</strong> {order.firstName}
            </p>
            <p>
              <strong>Nom:</strong> {order.lastName}
            </p>
            <p>
              <strong>Email:</strong> {order.email}
            </p>
            <p>
              <strong>Téléphone:</strong> {order.phone}
            </p>
          </div>
          <div className="devis-actions">
            <button onClick={() => updateStatus(order._id, "Accepté")}>Accepter</button>
            <button onClick={() => updateStatus(order._id, "Refusé")}>Refuser</button>
          </div>
        </article>
      ))}
    </section>
  )
}

export default GestionDevis
