import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { auth } from "../../auth/Auth"

const UpdateCake = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({
    name: "",
    disponible: false,
    category: "",
    label: "",
    description: "",
    composition: "",
    alergenes: "",
    conditioning: [{ libelle: "", price: "" }],
  })
  const [message, setMessage] = useState("")

  useEffect(() => {
    // Charger les données du gâteau à partir du backend en utilisant l'ID
    axios.get(`${process.env.REACT_APP_API}/details/${id}`, { headers: auth() }).then((res) => {
      const cakeData = res.data
      setFormData(cakeData)
    })
  }, [id])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleConditioningChange = (e, index) => {
    const { name, value } = e.target
    const updatedConditioning = [...formData.conditioning]
    updatedConditioning[index] = {
      ...updatedConditioning[index],
      [name]: value,
    }
    setFormData({
      ...formData,
      conditioning: updatedConditioning,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Envoyez les données du formulaire au backend pour mettre à jour le gâteau
    const formDataToUpdate = {
      name: formData.name,
      disponible: formData.disponible,
      category: formData.category,
      label: formData.label,
      description: formData.description,
      composition: formData.composition,
      alergenes: formData.alergenes,
      conditioning: formData.conditioning,
    }

    console.log(formData)
    axios
      .put(`${process.env.REACT_APP_API}/updatecakes/${id}`, formDataToUpdate, { headers: auth() })
      .then((res) => {
        // Le gâteau a été mis à jour avec succès, vous pouvez gérer ici la réponse du backend
        setMessage(res.data.message)
      })
      .catch((error) => {
        // Une erreur s'est produite lors de la mise à jour du gâteau, gérez l'erreur ici
        console.error("Erreur lors de la mise à jour du gâteau :", error)
      })
  }

  return (
    <section className="container">
      <h1>Modifier le Gâteau</h1>
      <form onSubmit={handleSubmit} className="form-cake">
        <div>
          <label htmlFor="name">Nom du Gâteau : </label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="disponible">Disponible : </label>
          <input type="checkbox" name="disponible" checked={formData.disponible} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="category">Catégorie : </label>
          <input type="text" name="category" value={formData.category} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="label">Label : </label>
          <input type="text" name="label" value={formData.label} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="description">Description : </label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="composition">Composition : </label>
          <input type="text" name="composition" value={formData.composition} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="alergenes">Allergènes : </label>
          <input type="text" name="alergenes" value={formData.alergenes} onChange={handleChange} />
        </div>
        {formData.conditioning.map((conditioningItem, index) => (
          <div key={index}>
            <label htmlFor={`libelle-${index}`}>Libellé : </label>
            <input
              type="text"
              name="libelle"
              value={conditioningItem.libelle}
              onChange={(e) => handleConditioningChange(e, index)}
            />
            <label htmlFor={`price-${index}`}>Prix : </label>
            <input
              type="text"
              name="price"
              value={conditioningItem.price}
              onChange={(e) => handleConditioningChange(e, index)}
            />
          </div>
        ))}
        <button type="submit">Mettre à jour</button>
      </form>
      {message}
    </section>
  )
}

export default UpdateCake
