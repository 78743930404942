import React from "react"

const AboutMe = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <main className="container-home">
        <section id="top">
          <figure>
            <img className="img-radius" src={"jason-adult.jpeg"} alt="Chef Jason Seban"></img>
          </figure>
          <h1 className="title">Jason Seban</h1>
          <h2 className="title">Qui suis-je ? </h2>
          <article className="description">
            <p>Pour pouvoir répondre à cette question revenons quelques (longues) années en arrière !</p>
            <p>
              Tout commence alors que je n’étais encore qu'un enfant. J'adorais aider ma grand-mère à faire des gâteaux,
              et comme tout enfant qui se respecte... et bien, je léchais toujours le fond du saladier (et oui, pas qu'à
              la cuillère !). Je venais de découvrir que j’étais très gourmand, que j’adorais cuisiner et
              particulièrement faire de la pâtisserie.
            </p>
            <figure>
              <img
                className="img-min"
                src={"baby-jason.jpeg"}
                alt="Jason enfant qui mange de la pâte à gâteau cru"
                aria-label="Image de Jason en train de manger de la pâte à gâteau cru lorsqu'il était enfant"
              ></img>
            </figure>
            <p>
              C'est alors qu'en 2008 que je me suis inscrit dans uné école pour apprendre la pâtisserie. Deux ans plus
              tard, j'ai obtenue mon diplome de Patissier. Et depuis, j’exerce ce métier avec toujours autant de passion
              !
            </p>
            <p>
              Lorsque je réalise un gâteau, je suis très généreux, très appliqué et surtout très attentif aux goûts et
              aux saveurs que j’utilise. En effet, ce que j’aime avant tout dans mes créations et mes réalisations,
              c’est l’alliance de textures et de saveurs. Je suis particulièrement à l'écoute de mes clients, à leurs
              attentes, leurs envies parfois les plus farfelues !
            </p>
            <figure>
              <img
                className="img-min"
                src={"jason-glacage.JPG"}
                alt="Décoration d'un gâteau avec un glacage rouge"
                aria-label="Image de Jason en train de manger de décorer un gâteau avec un glaçage rouge"
              ></img>
            </figure>
            <p>
              Si vous souhaitez en savoir plus sur mon parcours, ou bien sur la personnalisation d'un gâteau, alors
              n'hésitez pas à me contacter. Je me ferai un plaisir de prendre le temps de vous répondre et de vous aider
              à prendre la meilleure décision pour que votre gâteau soit parfait !
            </p>
          </article>
        </section>
      </main>
    </>
  )
}

export default AboutMe
