import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    } else if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let dataForm = {
      password: password,
      email: email,
    }

    axios.post(`${process.env.REACT_APP_API}/login`, dataForm).then((res) => {
      if (res.data.token) {
        localStorage.setItem("user", JSON.stringify(res.data))

        // Utilisez le navigateur pour rediriger l'utilisateur en fonction de l'adresse e-mail
        if (res.data.role === "Admin") {
          navigate("/gestion-admin-jaypastry")
          window.location.reload()
        } else {
          navigate("/mes-patisseries")
          window.location.reload()
        }
      }
      return res.data
    })
  }

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section>
        <form className="form-connexion" method="post" onSubmit={handleSubmit}>
          <label className="bold" htmlFor="identifiant">
            Email :
          </label>
          <input type="text" placeholder="Email" name="email" onChange={handleChange} value={email} />
          <label className="bold" htmlFor="motDePasse">
            Password :
          </label>
          <input type="password" name="password" placeholder="Mot de passe" onChange={handleChange} value={password} />
          <button className="btn btn-devis" type="submit">
            Envoyer
          </button>
        </form>
      </section>
    </>
  )
}

export default Login
