import { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Register = () => {
  const navigate = useNavigate() // Utilisez le hook useNavigate pour gérer la navigation
  const [email, setEmail] = useState("")
  const [login, setLogin] = useState("")
  const [message, setMessage] = useState("")
  const [password, setPassword] = useState("")

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    } else if (e.target.name === "password") {
      setPassword(e.target.value)
    } else if (e.target.name === "login") {
      setLogin(e.target.value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const checkPwd = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/
    if (!checkPwd.test(password)) {
      setMessage(
        "Le mot de passe ne respecte pas les conditions: 8 caractères, Majuscule, minuscule et caractères spéciaux. "
      )
      return
    }

    let dataForm = {
      login: login,
      password: password,
      email: email,
    }

    axios.post(`${process.env.REACT_APP_API}/register`, dataForm).then((res) => {
      setMessage(res.data.message)

      setTimeout(() => {
        // Si l'inscription est réussie, redirigez l'utilisateur vers la page souhaitée
        navigate("/login")
      }, 3000)
    })
  }

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section>
        <form className="form-connexion" method="post" onSubmit={handleSubmit}>
          <label className="bold" htmlFor="identifiant">
            Email :
          </label>
          <input type="text" placeholder="Email" name="email" onChange={handleChange} value={email} />

          <label className="bold" htmlFor="login">
            Login :
          </label>
          <input type="text" placeholder="Login" name="login" onChange={handleChange} value={login} />

          <label className="bold" htmlFor="motDePasse">
            Password :
          </label>
          <input type="password" name="password" placeholder="Mot de passe" onChange={handleChange} value={password} />

          <button className="btn btn-devis" type="submit">
            Envoyer
          </button>
          {message && <p>{message}</p>}
        </form>
      </section>
    </>
  )
}

export default Register
