import React from "react"
import { Link } from "react-router-dom"
const Administration = () => {
  return (
    <section className="container">
      <h1>Administration</h1>
      <section>
        <ul>
          <Link to="/gestion-gateaux">
            <h2>Gestion des gâteaux</h2>
          </Link>
          <Link to="/gestion-devis">
            <h2>Gestion des devis</h2>
          </Link>
          <Link to="/users">
            <h2>Gestion des utilisateurs</h2>
          </Link>

          <Link to="/bugs">
            <h2>Gestion des signalements</h2>
          </Link>
          <Link to="/gestion-recettes">
            <h2>Gestion des recettes</h2>
          </Link>
        </ul>
      </section>
    </section>
  )
}

export default Administration
