const Philosophie = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <article className="article-philo">
        <p>À mes yeux, la pâtisserie est bien plus qu'une simple activité culinaire.</p>
        <p>
          Je considère la pâtisserie comme un véritable moment de partage et de convivialité, une indulgence savoureuse
          pour les papilles.
        </p>
        <p>
          Je suis un passionné par l'art de mélanger les textures et les saveurs, et nous nous efforçons de créer des
          bouchées inoubliables pour chacune de nos créations.
        </p>
        <p>
          Chaque gâteau, chaque pâtisserie que je réalise est à mon image : généreuse, gourmande et toujours avec une
          touche de folie créative !
        </p>
        <p>Je suis ravi de vous accueillir sur mon site et de partager mon amour pour la pâtisserie avec vous.</p>
      </article>
    </>
  )
}

export default Philosophie
