import { Routes, Route } from "react-router-dom"
import "./App.css"
import Accueil from "./Pages/Accueil"
import Devis from "./Pages/Devis"
import MesPatisseries from "./Pages/MesPatisseries"
import MaPhilosophie from "./Pages/MaPhilosophie"
import Header from "./Components/Header"
import Footer from "./Components/Footer"
import NotFound from "./Pages/NotFound"
import Administration from "./Pages/Administration"
import GestionGateaux from "./Pages/Admin/GestionGateaux"
import UpdateCake from "./Pages/Admin/UpdateCake"
import AddCake from "./Pages/Admin/AddCake"
import GestionDevis from "./Pages/Admin/GestionDevis"
import Details from "./Pages/Details"
import AboutMe from "./Pages/AboutMe"
import Signalement from "./Pages/Admin/Signalement"
import SignalerUnBug from "./Pages/SignalerUnBug"
import Register from "./Pages/Register"
import Login from "./Pages/Login"
import AllUser from "./Pages/Admin/AllUser"
import { useEffect, useState } from "react"
import UserDevis from "./Pages/UserDevis"
import Recettes from "./Pages/Recettes"
import GestionRecettes from "./Pages/Admin/GestionRecettes"
import AddRecipe from "./Pages/Admin/AddRecipe"

function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || []
    if (user.role === "Admin") {
      setIsAdmin(true)
      setIsLogged(true)
    } else if (user.role === "User") {
      setIsLogged(true)
      setIsAdmin(false)
    } else if (user.length <= 0 || !user) {
      setIsAdmin && setIsLogged(false)
    }
  }, [isAdmin, isLogged])
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/mes-patisseries" element={<MesPatisseries />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/ma-philosophie" element={<MaPhilosophie />} />
        <Route path="/a-propos-de-moi" element={<AboutMe />} />
        <Route path="/devis" element={<Devis />} />
        <Route path="/recettes" element={<Recettes />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signaler-bug" element={<SignalerUnBug />} />

        {isLogged && <Route path="/user/orders" element={<UserDevis />} />}

        {isAdmin && isLogged ? (
          <>
            <Route path="/gestion-admin-jaypastry" element={<Administration />} />
            <Route path="/gestion-gateaux" element={<GestionGateaux />} />
            <Route path="/gestion-devis" element={<GestionDevis />} />
            <Route path="/ajouter-gateau" element={<AddCake />} />
            <Route path="/ajouter-recette" element={<AddRecipe />} />
            <Route path="/gestion-recettes" element={<GestionRecettes />} />
            <Route path="/bugs" element={<Signalement />} />
            <Route path="/edit-cakes/:id" element={<UpdateCake />} />
            <Route path="/users" element={<AllUser />} />
          </>
        ) : (
          ""
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
