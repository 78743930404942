import React from "react"
import { NavLink } from "react-router-dom"
import BurgerMenu from "./BurgerMenu"

const Header = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <header>
        <NavLink to="/" className="logo-link">
          <img className="logo" src={"/jaypastrylogo.JPG"} alt="logo du site JAY PASTRY" />
        </NavLink>
        <nav>
          <BurgerMenu />
        </nav>
      </header>
    </>
  )
}

export default Header
