import React from "react"

const NotFound = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section className="bold container">
        <p>Oh non ! Il semblerait que vous vous soyez perdu, comme notre pain ...</p>
        <p>
          Rassurez-vous, pour retrouver votre chemin cliquez
          <a className="NavLink" href="/mes-patisseries">
            lien magique
          </a>
          sur notre lien magique
        </p>
      </section>
    </>
  )
}

export default NotFound
