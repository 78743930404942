import Philosophie from "../Components/Philosophie.js"
import CharteDeQualite from "../Components/CharteDeQualite.js"

const MaPhilosophie = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section className="container">
        <Philosophie />
        <CharteDeQualite />
      </section>
    </>
  )
}

export default MaPhilosophie
