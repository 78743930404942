import React, { useState, useEffect, useRef } from "react"
import { NavLink, useNavigate } from "react-router-dom"

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const navigate = useNavigate() // Use the useNavigate hook

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const handleDelete = () => {
    localStorage.removeItem("user")
    setIsAdmin(false)
    setIsLogged(false)

    // Rediriger vers la page des pâtisseries
    navigate("/mes-patisseries")
    window.location.reload()
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu()
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || []
    if (user.role === "Admin") {
      setIsAdmin(true)
      setIsLogged(true)
    } else if (user.role === "User") {
      setIsLogged(true)
      setIsAdmin(false)
    } else if (user.length <= 0 || !user) {
      setIsAdmin && setIsLogged(false)
    }
  }, [isAdmin, isLogged])

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <div className="burger-menu" ref={menuRef}>
        <button onClick={toggleMenu} className="burger-button">
          ☰
        </button>
        {isOpen && (
          <nav className="menu-items">
            <NavLink to="/" onClick={toggleMenu}>
              Accueil
            </NavLink>
            <NavLink to="/mes-patisseries" onClick={toggleMenu}>
              Mes Pâtisseries
            </NavLink>
            <NavLink to="/a-propos-de-moi" onClick={toggleMenu}>
              Qui suis-je?
            </NavLink>
            <NavLink to="/devis" onClick={toggleMenu}>
              Devis
            </NavLink>
            {isLogged && (
              <NavLink className="NavLink" to="/user/orders">
                Mes devis
              </NavLink>
            )}
            {isLogged ? (
              <NavLink onClick={handleDelete}> Déconnexion</NavLink>
            ) : (
              <>
                <NavLink to="/login" onClick={toggleMenu}>
                  Connexion
                </NavLink>
                <NavLink to="/register" onClick={toggleMenu}>
                  Inscription
                </NavLink>
              </>
            )}
            {isAdmin && (
              <NavLink to="/gestion-admin-jaypastry" onClick={toggleMenu}>
                Gestion-Admin
              </NavLink>
            )}
          </nav>
        )}
        <nav className="nav2">
          <NavLink className="NavLink" to="/">
            Accueil
          </NavLink>
          <NavLink className="NavLink" to="/mes-patisseries">
            Mes Pâtisseries
          </NavLink>
          <NavLink className="NavLink" to="/a-propos-de-moi">
            Qui suis-je ?
          </NavLink>
          <NavLink className="NavLink" to="/devis">
            Devis
          </NavLink>
          {isLogged && (
            <NavLink className="NavLink" to="/user/orders">
              Mes devis
            </NavLink>
          )}
          {isLogged ? (
            <NavLink className="NavLink" onClick={handleDelete}>
              Déconnexion
            </NavLink>
          ) : (
            <>
              <NavLink className="NavLink" to="/login">
                Connexion
              </NavLink>
              <NavLink className="NavLink" to="/register">
                Inscription
              </NavLink>
            </>
          )}
          {isAdmin && (
            <NavLink className="NavLink" to="/gestion-admin-jaypastry">
              Gestion-Admin
            </NavLink>
          )}
        </nav>
      </div>
    </>
  )
}

export default BurgerMenu
