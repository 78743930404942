import React, { useState } from "react"
import axios from "axios"
import { auth } from "../../auth/Auth"

const AddCake = () => {
  const [formData, setFormData] = useState({
    name: "",
    disponible: false,
    category: "",
    label: "",
    description: "",
    composition: "",
    alergenes: "",
    img: "",
    conditioning: [{ libelle: "", price: "" }],
  })
  const [message, setMessage] = useState("")

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    if (e.target.name === "img") {
      setFormData({
        ...formData,
        img: e.target.files[0],
      })
    }
  }

  const handleConditioningChange = (e, index) => {
    const { name, value } = e.target
    const updatedConditioning = [...formData.conditioning]
    updatedConditioning[index] = {
      ...updatedConditioning[index],
      [name]: value,
    }
    setFormData({
      ...formData,
      conditioning: updatedConditioning,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let newCakeData = new FormData()
    console.log(formData)
    newCakeData.append("name", formData.name)
    newCakeData.append("disponible", formData.disponible)
    newCakeData.append("category", formData.category)
    newCakeData.append("label", formData.label)
    newCakeData.append("description", formData.description)
    newCakeData.append("composition", formData.composition)
    newCakeData.append("alergenes", formData.alergenes)
    newCakeData.append("img", formData.img)
    newCakeData.append("libelle", formData.conditioning[0].libelle)
    newCakeData.append("price", formData.conditioning[0].price)

    axios
      .post(`${process.env.REACT_APP_API}/addnewcake`, newCakeData, { headers: auth() })
      .then((res) => {
        setMessage(res.data.message)
        setFormData({
          name: "",
          disponible: false,
          category: "",
          label: "",
          description: "",
          composition: "",
          alergenes: "",
          img: null,
          conditioning: [{ libelle: "", price: "" }],
        })
      })
      .catch((error) => {
        console.error("Erreur lors de l'ajout du gâteau :", error)
      })
  }

  return (
    <section className="container">
      <h1>Ajouter un nouveau gâteau</h1>
      <form encType="multipart/form-data" onSubmit={handleSubmit} className="form-cake">
        <div>
          <label htmlFor="name">Nom du Gâteau : </label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="category">Catégorie : </label>
          <input type="text" name="category" value={formData.category} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="disponible">Disponible : </label>
          <input
            type="checkbox"
            name="disponible"
            checked={formData.disponible}
            onChange={(e) => setFormData({ ...formData, disponible: e.target.checked })}
          />
        </div>
        <div>
          <label htmlFor="label">Label : </label>
          <input type="text" name="label" value={formData.label} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="description">Description : </label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="composition">Composition : </label>
          <input type="text" name="composition" value={formData.composition} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="alergenes">Allergènes : </label>
          <input type="text" name="alergenes" value={formData.alergenes} onChange={handleChange} />
        </div>
        {formData.conditioning.map((conditioningItem, index) => (
          <div key={index}>
            <label htmlFor={`libelle-${index}`}>Nombre de personnes : </label>
            <input
              type="text"
              name="libelle"
              value={conditioningItem.libelle}
              onChange={(e) => handleConditioningChange(e, index)}
            />
            <label htmlFor={`price-${index}`}>Prix : </label>
            <input
              type="text"
              name="price"
              value={conditioningItem.price}
              onChange={(e) => handleConditioningChange(e, index)}
            />
          </div>
        ))}
        <div>
          <label htmlFor="img">Image : </label>
          <input
            type="file"
            name="img"
            onChange={(e) => {
              setFormData({
                ...formData,
                img: e.target.files[0],
              })
            }}
          />
        </div>

        <button type="submit">Ajouter</button>
      </form>
      {message}
    </section>
  )
}

export default AddCake
