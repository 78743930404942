import React, { useState, useEffect } from "react"
import axios from "axios"
import { auth } from "../../auth/Auth"

const GestionUtilisateurs = () => {
  const [users, setUsers] = useState([])
  const [message, setMessage] = useState("")

  const fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API}/users`, { headers: auth() })
      .then((res) => {
        setUsers(res.data)
        setMessage(res.data.message)
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des utilisateurs :", error)
      })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <section className="container">
      <h1>Gestion des Utilisateurs</h1>

      <table className="table-border">
        <thead>
          <tr>
            <th>Nom d'Utilisateur</th>
            <th>Email</th>
            <th>Rôle</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.login}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {message}
    </section>
  )
}

export default GestionUtilisateurs
