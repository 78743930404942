import React, { useEffect, useState } from "react"
import axios from "axios"

const UserDevis = () => {
  const [userOrders, setUserOrders] = useState([])
  const [userLogin, setUserLogin] = useState("")

  // Utilisez l'e-mail de l'utilisateur actuellement connecté s'il est disponible dans le localStorage
  const user = JSON.parse(localStorage.getItem("user"))
  const userEmail = user ? user.email : ""
  const token = user ? user.token : null

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  // Fonction pour récupérer l'URL de l'image du gâteau en fonction du nom du gâteau
  const getCakeImage = (cakeName) => {
    const cakes = JSON.parse(localStorage.getItem("cakes"))
    const cake = cakes.find((cake) => cake.name === cakeName)
    return cake ? `${process.env.REACT_APP_API}/img/${cake.img[0]}` : ""
  }

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        // Appelez votre endpoint backend pour récupérer les devis de l'utilisateur
        const response = await axios.get(`${process.env.REACT_APP_API}/user/orders/${userEmail}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        // Triez les devis par date d'arrivée décroissante avant de mettre à jour l'état
        const sortedOrders = response.data.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        // Mettez à jour l'état avec les devis triés
        setUserOrders(sortedOrders)

        // Récupérer le login de l'utilisateur
        setUserLogin(user ? user.login : "")
      } catch (error) {
        console.error("Erreur lors de la récupération des devis:", error)
      }
    }

    // Appelez la fonction pour récupérer les devis lors du montage du composant
    fetchUserOrders()
  }, [userEmail, token, user])

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section className="container-user">
        <h1>Mes Devis</h1>
        <p>
          Bonjour <strong>{userLogin}</strong>, bienvenue sur votre espace personnel où vous pourrez avoir accès à vos
          devis en temps réel.
        </p>
        {userOrders.map((order) => (
          <div key={order._id} className="order-item">
            <div>
              <p>
                <strong>Gâteau :</strong> {order.cake}
              </p>
              <p>
                <strong>Taille :</strong> {order.size}
              </p>
              <p>
                <strong>Date :</strong> {formatDate(order.date)}
              </p>
              <p>
                <strong>Statut :</strong>
                <span
                  style={{
                    backgroundColor:
                      order.status === "Accepté" ? "green" : order.status === "Refusé" ? "red" : "orange",
                    padding: "2px 5px",
                    borderRadius: "5px",
                    display: "inline-block",
                  }}
                >
                  {order.status}
                </span>
              </p>
            </div>
            <div>
              {/* Utilise la fonction pour récupérer l'URL de l'image du gâteau */}
              <img className="img-user" src={getCakeImage(order.cake)} alt={order.cake} />
            </div>
          </div>
        ))}
      </section>
    </>
  )
}

export default UserDevis
