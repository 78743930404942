const CharteDeQualite = () => {
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <h2>Charte de qualité </h2>
      <section className="container-charte">
        <article className="article-charte">
          <h3>Qualité des produits :</h3>
          <p>
            Chez Jay Pastry, je m'engageons à n'utiliser que des ingrédients de qualité supérieure pour une expérience
            gustative exceptionnelle.
          </p>
        </article>
        <article className="article-charte">
          <h3>Personnalisation sur mesure :</h3>
          <p>Des gâteaux personnalisables pour rendre chaque célébration spéciale et unique.</p>
        </article>
        <article className="article-charte">
          <h3>Livraison fiable :</h3>
          <p>Je mettrai tout en oeuvre pour assurer une livraison optimale de nos produits.</p>
        </article>
        <article className="article-charte">
          <h3>Click & Collect :</h3>
          <p>
            Je met à disposition un service de Click & Collect. Ce qui vous permettra de choisir un créneau horaire qui
            vous convient et d'économiser sur les frais de livraison.
          </p>
        </article>
        <article className="article-charte">
          <h3>Service client :</h3>
          <p>
            Je considère chacun de mes clients et m'engage à offrir un service de qualité. Je serai disponible pour
            répondre à toutes vos questions, discuter de vos devis, prendre en compte vos demandes spécifiques et vous
            guider dans votre choix.
          </p>
        </article>
      </section>
      <div className="flex">
        <p className="p">8 juin 2023</p>
      </div>
    </>
  )
}

export default CharteDeQualite
