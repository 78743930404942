import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { auth } from "../../auth/Auth"

const GestionRecettes = () => {
  const [recettes, setRecettes] = useState([])
  const [message, setMessage] = useState("")

  // Fonction pour récupérer la liste des recettes depuis le backend
  const fetchRecettes = () => {
    axios
      .get(`${process.env.REACT_APP_API}/recettes`, { headers: auth() })
      .then((res) => {
        setRecettes(res.data)
        setMessage(res.data.message)
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des recettes :", error)
      })
  }

  useEffect(() => {
    fetchRecettes()
  }, [])

  // Fonction pour supprimer une recette
  const handleDeleteRecette = (recetteId) => {
    // Demander une confirmation avant la suppression
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette recette ?")

    if (confirmation) {
      axios
        .delete(`${process.env.REACT_APP_API}/deleterecipe/${recetteId}`, { headers: auth() })
        .then((res) => {
          console.log("Recette supprimée :", res.data)
          // Rafraîchir la liste des recettes après la suppression
          fetchRecettes()
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression de la recette :", error)
        })
    }
  }

  return (
    <section className="container">
      <h1>Gestion des Recettes</h1>
      <Link to="/ajouter-recette">
        <button>Ajouter une nouvelle recette</button>
      </Link>

      <table className="table-border">
        <thead>
          <tr>
            <th>Titre de la Recette</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {recettes.map((recette) => (
            <tr key={recette._id}>
              <td>{recette.titre}</td>
              <td>
                <Link to={`/modifier-recette/${recette._id}`}>
                  <button>Modifier</button>
                </Link>
                <button onClick={() => handleDeleteRecette(recette._id)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {message}
    </section>
  )
}

export default GestionRecettes
