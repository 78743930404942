import axios from "axios"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

const NosPatisseries = () => {
  const [getCakes, setGetCakes] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/cakes`).then((res) => {
      setGetCakes(res.data)
      // Stocke les informations des gâteaux dans le localStorage
      localStorage.setItem("cakes", JSON.stringify(res.data))
    })
  }, [])

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <article className="container-nos-patisseries">
        {getCakes.map((oneGetCake, i) => (
          <section>
            <article className="card" key={i}>
              <Link to={`/details/${oneGetCake._id}`}>
                <figure className="container-img">
                  <div className="box-img">
                    <img
                      className="card-img"
                      src={`${process.env.REACT_APP_API}/img/${oneGetCake.img[0]}`}
                      alt={oneGetCake.name}
                    />
                  </div>
                  <figcaption>
                    <div className="position">
                      <p className="name">{oneGetCake.name}</p>
                      {oneGetCake.label && <p className="label">{oneGetCake.label}</p>}
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </article>
          </section>
        ))}
      </article>
    </>
  )
}

export default NosPatisseries
