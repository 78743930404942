import React, { useEffect, useState } from "react"
import axios from "axios"

const SignalerUnBug = () => {
  const [message, setMessage] = useState("")
  const [bug, setBug] = useState({})

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/bugs`).then((res) => {
      setBug({
        name: res.name,
        email: res.email,
        explication: res.explication,
      })
    })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const bugData = {
      name: bug.name,
      email: bug.email,
      explication: bug.explication,
    }
    console.log("bugData", bugData)
    axios.post(`${process.env.REACT_APP_API}/addbugs`, bugData).then((res) => {
      setMessage(res.data.message)
      setBug({})
    })
    console.log("formulaire soumis avec succès")
  }
  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <div className="container">
        <h2>Signaler un problème </h2>
        <p>
          Merci de bien vouloir prendre le temps de nous expliquer la situation en détails pour pouvoir la résoudre au
          plus vite.
        </p>
        <form className="formulaire-devis" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Nom :</label>
            <input
              type="text"
              id="name"
              placeholder="Ton nom"
              alt="Ton Nom"
              onChange={(e) => setBug({ ...bug, name: e.target.value })}
            />
          </div>
          <div>
            <label htmlFor="email">Email :</label>
            <input
              type="email"
              id="email"
              placeholder="Ton adresse mail"
              alt="Ton adresse mail"
              onChange={(e) => setBug({ ...bug, email: e.target.value })}
            />
          </div>
          <div>
            <label htmlFor="explication">Explication :</label>
            <textarea
              id="explication"
              cols="30"
              rows="5"
              placeholder="Explique-nous le problème rencontré"
              alt="Explique-nous le problème rencontré"
              onChange={(e) => setBug({ ...bug, explication: e.target.value })}
            />
          </div>
          <button type="submit">Envoyer </button>
        </form>
        {message}
      </div>
    </>
  )
}

export default SignalerUnBug
